<template>
  <div>
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">Contact Information Edit</h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Close</CButton
        >
        <CButton
          type="button"
          color="primary"
          class="px-4"
          @click="onSubmit()"
          v-if="activeTab === 1"
          >Save</CButton
        >
      </template>
      <LoadingOverlay v-if="!isFetching" />
      <div v-else>
        <CTabs variant="tabs" :active-tab.sync="activeTab">
          <CTab title="CV / Resume Preview">
            <div class="p-2">
              <CVPreview />
            </div>
          </CTab>
          <CTab title="Contact Information Edit">
            <div class="p-2">
              <ValidationObserver
                ref="editContactInfo"
                v-slot="{ handleSubmit }"
              >
                <form
                  @submit.prevent="handleSubmit(onSubmit)"
                  name="editContactInfo"
                >
                  <CRow>
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_email"
                          class="required col-lg-12 col-md-12"
                          >Email</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            rules="required|email"
                            v-slot="{ errors }"
                          >
                            <TextInput
                              name="email"
                              :disabled="true"
                              :value="profile.email"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_alternate_email"
                          class="col-lg-12 col-md-12"
                          >Alternate Email</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider rules="email" v-slot="{ errors }">
                            <TextInput
                              name="alternate_email"
                              :value="profile.alternate_email"
                              @change="handleInput"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_phone"
                          class="required col-lg-12 col-md-12"
                          >Phone</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            ref="phone"
                            :rules="{
                              required: true,
                              phone_rule: /^\+\d{1,3}\^\d{1,15}$/,
                            }"
                            v-slot="{ errors }"
                          >
                            <PhoneInputwithCountry
                              name="phone"
                              :value="profile.phone"
                              :options="
                                options && options['dialingCode']
                                  ? options['dialingCode']
                                  : []
                              "
                              @change="handleInput"
                              :error="errors[0]"
                              ref="phoneNo"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_alternate_phone"
                          class="col-lg-12 col-md-12"
                          >Alternate Phone</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            ref="alternate_phone"
                            :rules="{ phone_rule: /^\+\d{1,3}\^\d{1,15}$/ }"
                            v-slot="{ errors }"
                          >
                            <PhoneInputwithCountry
                              name="alternate_phone"
                              :value="profile.alternate_phone"
                              :options="
                                options && options['dialingCode']
                                  ? options['dialingCode']
                                  : []
                              "
                              @change="handleInput"
                              :error="errors[0]"
                              ref="alternatePhone"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6">
                      <CRow
                        class="row mb-3"
                        :class="{
                          requiredfield: !profile.whatsapp && whatsappRequired,
                        }"
                      >
                        <label
                          class="col-lg-12 col-md-12"
                          :class="{ required: whatsappRequired }"
                          >WhatsApp</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            ref="whatsapp"
                            :rules="{
                              phone_rule: /^\+\d{1,3}\^\d{1,15}$/,
                              required: whatsappRequired,
                            }"
                            v-slot="{ errors }"
                          >
                            <PhoneInputwithCountry
                              name="whatsapp"
                              :value="profile.whatsapp"
                              :options="
                                options && options['dialingCode']
                                  ? options['dialingCode']
                                  : []
                              "
                              @change="handleInput"
                              :error="errors[0]"
                              ref="whatsappNo"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                    <CCol lg="6">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_preferred_contact_method"
                          class="col-lg-12 col-md-12"
                          >Preferred Contact Method</label
                        >
                        <div class="col infoicon-multi-select">
                          <Select
                            name="preferred_contact_method"
                            :value="profile.preferred_contact_method"
                            :options="
                              options && options['preferred_contact_method']
                                ? options['preferred_contact_method']
                                : []
                            "
                            @input="handleChangeSelect"
                            :taggable="false"
                            :multiple="true"
                            :clearable="true"
                          />
                        </div>
                        <div class="position-relative multi-select-icon-div">
                          <CIcon
                            name="cil-info"
                            class="hinticon-multi-select"
                            v-c-tooltip="'Select one or more'"
                          />
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_linkedin_address"
                          class="col-lg-12 col-md-12"
                          >LinkedIn Address</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <TextInput
                            name="linkedin_address"
                            :value="profile.linkedin_address"
                            @change="handleInput"
                          />
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label name="lbl_skype_id" class="col-lg-12 col-md-12"
                          >Skype</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <TextInput
                            name="skype_id"
                            :value="profile.skype_id"
                            @change="handleInput"
                          />
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label name="lbl_country_id" class="col-lg-12 col-md-12"
                          >Currently Working In</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <Select
                            name="country_id"
                            :value="profile.country_id"
                            :options="
                              options && options.countryInfo
                                ? options.countryInfo
                                : []
                            "
                            @change="handleChangeSelect"
                            :taggable="false"
                            :multiple="false"
                            :clearable="true"
                          />
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_personal_website"
                          class="col-lg-12 col-md-12"
                          >Personal Website</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <TextInput
                            name="personal_website"
                            :value="profile.personal_website"
                            @change="handleInput"
                          />
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                </form>
              </ValidationObserver>
            </div>
          </CTab>
        </CTabs>
      </div>
    </CModal>
  </div>
</template>
<script>
import { extend } from "vee-validate";
import { required, email, numeric } from "vee-validate/dist/rules";
import { regex } from "vee-validate/dist/rules";
import EditSection from "./EditSection";
import { mapActions, mapGetters } from "vuex";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";

extend("required", { ...required, message: "This field is required" });
extend("email", { ...email, message: "Invalid email" });
extend("phone_rule", {
  ...regex,
  message: "Only numbers are allowed(Maximum of 15 digits)",
});
extend("numeric", { ...numeric, message: "Only numbers are allowed" });
export default {
  mixins: [EditSection],
  props: {
    candidateInfo: {
      type: Object,
      default: null,
    },
    isShowPopup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      profile: {},
    };
  },
  computed: {
    ...mapGetters([
      "locationCountryInfo",
      "dialingCodeWithCountryCode",
      "isFetchingFilter",
    ]),
    options() {
      return {
        countryInfo: this.locationCountryInfo || [],
        dialingCode: this.dialingCodeWithCountryCode || [],
        preferred_contact_method: [
          { id: "phone", label: "Phone" },
          { id: "whatsapp", label: "WhatsApp" },
          { id: "email", label: "Email" },
          { id: "sms", label: "SMS" },
        ],
      };
    },
    isFetching() {
      if (
        isObject(this.candidateInfo) &&
        !isEmptyObjectCheck(this.candidateInfo) &&
        !this.isFetchingFilter
      ) {
        let profileData = this.candidateInfo;
        this.profile = {
          ...profileData,
          customer_uid: profileData?.customer_uid,
          candidate_uid: profileData?.candidate_uid,
          email: profileData?.email,
          alternate_email: this.isSameEmail(
            profileData?.email,
            profileData?.alternate_email
          )
            ? null
            : profileData?.alternate_email,
          phone: profileData.phone !== null ? profileData.phone : "",
          alternate_phone:
            profileData.alternate_phone !== null
              ? profileData.alternate_phone
              : "",
          whatsapp: profileData.whatsapp !== null ? profileData.whatsapp : "",
          linkedin_address: profileData?.linkedin_address,
          skype_id: profileData?.skype_id,
          personal_website: profileData?.personal_website,
          country_id: profileData.country_id
            ? this.locationCountryInfo.filter(
                (val) => val.code === profileData.country_id
              )[0]
            : null,
          preferred_contact_method:
            profileData.preferred_contact_method !== null
              ? this.preferredContact(profileData.preferred_contact_method)
              : [],
        };
        return true;
      }
      return false;
    },
    whatsappRequired() {
      return (
        this.profile.preferred_contact_method &&
        this.profile.preferred_contact_method.some((e) => e.id === "whatsapp")
      );
    },
  },
  methods: {
    ...mapActions(["initFetchContactInfoOptions", "showToast"]),
    isSameEmail(email1, email2) {
      if (email1 && email2) {
        const email_1 = email1.toLowerCase().trim();
        const email_2 = email2.toLowerCase().trim();
        return email_1 === email_2 || false;
      }
      return false;
    },
    preferredContact(data) {
      const val = data.split(", ");
      let preferredarr = [];
      for (var i = 0; i < val.length; i++) {
        var jsonObj = {
          id: val[i],
          label:
            val[i].charAt(0).toUpperCase() + val[i].substr(1).toLowerCase(),
        };
        preferredarr.push(jsonObj);
      }
      return preferredarr;
    },
    clearFieldErrors(name, hasError) {
      this.$nextTick(() => {
        if (name === hasError.phone) {
          this.$refs.phone.errors.clear;
          this.$refs.phone.reset();
        } else if (name === hasError.whatsapp) {
          this.$refs.whatsapp.errors.clear;
          this.$refs.whatsapp.reset();
        } else if (name === hasError.alternate_phone) {
          this.$refs.alternate_phone.errors.clear;
          this.$refs.alternate_phone.reset();
        }
      });
    },
    async onSubmit() {
      const isValid = await this.$refs.editContactInfo.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      this.payload = {
        ...this.payload,
        candidate_uid: this.profile?.candidate_uid,
        phone: this.profile?.phone,
        alternate_phone: this.profile?.alternate_phone,
        whatsapp: this.profile?.whatsapp,
        linkedin_address: this.profile?.linkedin_address,
        skype_id: this.profile?.skype_id,
        personal_website: this.profile?.personal_website,
      };
      if(!this.isSameEmail(this.profile?.email,this.profile?.alternate_email) || !this.isSameEmail(this.profile?.alternate_email, this.candidateInfo?.alternate_email)){
        this.payload = {
          ...this.payload,
          alternate_email: this.profile?.alternate_email,
        }
      }else if(this.payload?.alternate_email){
        delete this.payload.alternate_email;
      }
      this.modalCallBack(true, this.payload);
    },
    modalCallBack(action, payload) {
      this.$emit("modalCallBack", action, payload);
    },
  },
  mounted() {
    this.initFetchContactInfoOptions();
  },
};
</script>
<style lang="scss" scoped>
.hinticon {
  color: #dc0050 !important;
}
</style>