<template>
  <div>
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">Availability Information Edit</h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Close</CButton
        >
        <CButton
          type="button"
          color="primary"
          class="px-4"
          @click="onSubmit()"
          v-if="activeTab === 1"
          >Save</CButton
        >
      </template>
      <LoadingOverlay v-if="!isFetching" />
      <div v-else>
        <CTabs variant="tabs" :active-tab.sync="activeTab">
          <CTab title="CV / Resume Preview">
            <div class="p-2">
              <CVPreview />
            </div>
          </CTab>
          <CTab title="Availability Information Edit">
            <div class="p-2">
              <ValidationObserver
                ref="editAvailabilityInfo"
                v-slot="{ handleSubmit }"
              >
                <form
                  @submit.prevent="handleSubmit(onSubmit)"
                  name="editAvailabilityInfo"
                >
                  <CRow>
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label name="lbl_available" class="col-lg-12 col-md-12"
                          >Actively Seeking Employment</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <RadioButton
                            name="available"
                            :value="profile.available"
                            :options="
                              options && options['available']
                                ? options['available']
                                : []
                            "
                            @change="handleChangeRadio"
                          />
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_notice_period_days"
                          class="col-lg-12 col-md-12"
                          v-if="profile.notice_period_days > 0"
                          >Notice Period ({{
                            profile.notice_period_days
                          }}
                          days)</label
                        >
                        <label class="col-lg-12 col-md-12" v-else
                          >Notice Period (in days)</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <v-slider
                            name="notice_period_days"
                            :min="minDependants"
                            :max="maxDependants"
                            :value="profile.notice_period_days"
                            @change="onChange"
                          />
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow md="6">
                    <CCol md="6">
                      <!-- <div v-if="profile.available == true">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_expected_join_date"
                            class="col-lg-12 col-md-12"
                            >Join Date/Availability</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="date_validate|dateBetweenCurrentMth"
                              v-slot="{ errors }"
                            >
                              <DateInput
                                :futureYear="true"
                                :onlyMonthAndYear="true"
                                name="expected_join_date"
                                :value="profile.expected_join_date"
                                @change="handleDatePickerChange"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </div> -->
                      <div>
                        <CRow class="row mb-3">
                          <label
                            name="lbl_available"
                            class="col-lg-12 col-md-12"
                            >Available From</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="date_validate|dateBetween"
                              v-slot="{ errors }"
                            >
                              <DateInput
                                :futureYear="true"
                                :onlyMonthAndYear="true"
                                name="available_from"
                                :value="profile.available_from"
                                @change="handleDatePickerChange"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </div>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_preferred_regions"
                          class="col-lg-12 col-md-12"
                          >Preferred Regions</label
                        >
                        <div class="col infoicon-multi-select">
                          <Select
                            name="preferred_regions"
                            :value="profile['preferred_regions']"
                            @change="handleChangeMultiSelectAsArray"
                            :options="
                              options && options['locationRegion']
                                ? options['locationRegion']
                                : []
                            "
                            :taggable="false"
                            :multiple="true"
                            :clearable="true"
                          />
                        </div>
                        <div class="position-relative multi-select-icon-div">
                          <CIcon
                            name="cil-info"
                            class="hinticon-multi-select"
                            v-c-tooltip="'Select one or more'"
                          />
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_preferred_regions"
                          class="col-lg-12 col-md-12"
                          >Preferred Country</label
                        >
                        <div class="col infoicon-multi-select">
                          <Select
                            name="preferred_country_id"
                            :value="profile.preferred_country_id"
                            @change="handleChangeMultiSelectAsArray"
                            :options="
                              options && options['preferred_country']
                                ? options['preferred_country']
                                : []
                            "
                            :taggable="false"
                            :multiple="true"
                            :clearable="true"
                          />
                        </div>
                        <div class="position-relative multi-select-icon-div">
                          <CIcon
                            name="cil-info"
                            class="hinticon-multi-select"
                            v-c-tooltip="'Select one or more'"
                          />
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_current_salary"
                          class="col-lg-12 col-md-12"
                          >Current Salary</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <CRow>
                            <CCol class="pr-0" style="max-width: 50%">
                              <ValidationProvider
                                rules="numeric"
                                v-slot="{ errors }"
                              >
                                <TextInput
                                  name="current_salary"
                                  :value="profile.current_salary"
                                  :min="0"
                                  type="Number"
                                  CSS_Style="max-height: 31px"
                                  @change="handleInput"
                                  :error="errors[0]"
                                  :onkeydown="restrictedKeys"
                                />
                              </ValidationProvider>
                            </CCol>
                            <CCol class="p-0 ml-1" style="max-width: 25%">
                              <Select
                                name="current_salary_currency_id"
                                :value="profile.current_salary_currency_id"
                                @change="handleChangeSelect"
                                :options="
                                  options && options['currency']
                                    ? options['currency']
                                    : []
                                "
                                :taggable="false"
                                :clearable="false"
                                :disabled="isDisabled"
                              />
                            </CCol>
                          </CRow>
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_salary_expectation"
                          class="col-lg-12 col-md-12"
                          >Salary Expectation(Min - Max)</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <CRow>
                            <CCol class="pr-0" style="max-width: 30%">
                              <ValidationProvider
                                name="salary_expectation_min"
                                rules="numeric"
                                v-slot="{ errors }"
                              >
                                <TextInput
                                  name="salary_expectation_min"
                                  :value="profile.salary_expectation_min"
                                  placeholder="Min"
                                  :min="0"
                                  type="Number"
                                  CSS_Style="max-height: 31px"
                                  @change="handleInput"
                                  :onkeydown="restrictedKeys"
                                  :error="errors[0]"
                                />
                              </ValidationProvider>
                            </CCol>
                            _
                            <CCol class="p-0" style="max-width: 30%">
                              <ValidationProvider
                                name="salary_expectation_max"
                                rules="numeric|minMaxCheck:@salary_expectation_min"
                                v-slot="{ errors }"
                              >
                                <TextInput
                                  name="salary_expectation_max"
                                  :value="profile.salary_expectation_max"
                                  placeholder="Max"
                                  :min="0"
                                  type="Number"
                                  CSS_Style="max-height: 31px"
                                  @change="handleInput"
                                  :onkeydown="restrictedKeys"
                                  :error="errors[0]"
                                />
                              </ValidationProvider>
                            </CCol>
                            <CCol class="ml-1 p-0" style="max-width: 25%">
                              <Select
                                name="salary_expectation_currency_id"
                                :value="profile.salary_expectation_currency_id"
                                @change="handleChangeSelect"
                                :options="
                                  options && options['currency']
                                    ? options['currency']
                                    : []
                                "
                                :taggable="false"
                                :clearable="false"
                                :disabled="isDisabled"
                              />
                            </CCol>
                          </CRow>
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label class="col-lg-12 col-md-12">Contract Type</label>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <div class="d-flex field-align">
                            <div class="flex-fill mr-1">
                              <Select
                                name="job_type"
                                :value="profile.job_type"
                                @change="handleChangeSelect"
                                :options="
                                  options && options['jobType']
                                    ? options['jobType']
                                    : []
                                "
                                :taggable="false"
                                :multiple="false"
                                :clearable="true"
                              />
                            </div>
                            <div class="flex-fill mr-1">
                              <Select
                                name="job_time"
                                :value="profile.job_time"
                                @change="handleChangeSelect"
                                :options="
                                  options && options['jobTime']
                                    ? options['jobTime']
                                    : []
                                "
                                :taggable="false"
                                :multiple="false"
                                :clearable="true"
                              />
                            </div>
                          </div>
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                </form>
              </ValidationObserver>
            </div>
          </CTab>
        </CTabs>
      </div>
    </CModal>
  </div>
</template>
<script>
const debounce = require("lodash.debounce");
import Vue from "vue";
import EditSection from "./EditSection";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
import m from "moment";
import { extend } from "vee-validate";
import { required, numeric } from "vee-validate/dist/rules";
import { mapActions, mapGetters } from "vuex";
extend("numeric", { ...numeric, message: "Only numbers are allowed" });
extend("required", { ...required, message: "This field is required" });
extend("dateBetween", (value) => {
  let date = m(value).format("MM/DD/YYYY");
  let fromDate = m().format("MM/DD/YYYY");
  let toDate = m(fromDate).add(3, "y").format("MM/DD/YYYY");
  if (m(date).isBetween(fromDate, toDate, undefined, [])) {
    return true;
  }
  return "Date should be within 3 years from today";
});
extend("dateBetweenCurrentMth", (value) => {
  let date = m(value).format("MM/DD/YYYY");
  let fromDate = m().format("MM/DD/YYYY");
  let toDate = m(fromDate).add(3, "y").format("MM/DD/YYYY");
  if (
    m(date).isBetween(fromDate, toDate, undefined, [])
    // || m(date).isSame(fromDate, "month")
  ) {
    return true;
  }
  return "Date should be within 3 years from today";
});
extend("date_validate", (value) => {
  if (m(value).isValid()) {
    return true;
  }
  return "Invalid date! Enter a valid date";
});
extend("minMaxCheck", {
  params: ["salary_expectation_min"],
  validate(value, { salary_expectation_min }) {
    if (value - salary_expectation_min > 0) {
      return true;
    }
  },
  message: "Should be greater than min salary",
});

export default {
  mixins: [EditSection],
  props: {
    candidateInfo: {
      type: Object,
      default: null,
    },
    isShowPopup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      profile: {},
      errors: [],
      minDependants: 0,
      maxDependants: 180,
      isDisabled: false,
      restrictedKeys:
        "javascript: return [8,9,46].includes(event.keyCode) ? true : !isNaN(Number(event.key))",
    };
  },
  computed: {
    ...mapGetters([
      "PreferredCountryFilterByRegion",
      "locationRegion",
      "getCandidatePreferredRegionList",
      "getCandidatePreferredCountryList",
      "getCurrency",
    ]),
    isFetching() {
      if (
        isObject(this.candidateInfo) &&
        !isEmptyObjectCheck(this.candidateInfo) &&
        !this.isFetchingFilter
      ) {
        let profileData = this.candidateInfo;
        let preferred_country = this.getCandidatePreferredCountryList || [];
        let preferred_regions = this.getCandidatePreferredRegionList || [];
        this.profile = {
          ...profileData,
          customer_uid: profileData.customer_uid,
          candidate_uid: profileData.candidate_uid,
          available: profileData?.available,
          preferred_country_id: preferred_country,
          preferred_locations: null,
          preferred_regions: preferred_regions,
          notice_period_days: profileData?.notice_period_days,
          expected_join_date: profileData.expected_join_date
            ? profileData.expected_join_date
            : null,
          available_from: profileData.available_from
            ? profileData.available_from
            : null,
          salary_expectation_min: profileData?.salary_expectation_min,
          salary_expectation_max: profileData?.salary_expectation_max,
          salary_expectation_currency_id:
            profileData.salary_expectation_currency
              ? profileData?.salary_expectation_currency?.currency_code
              : {
                  label: "USD",
                  code: 229,
                },
          current_salary: /^\d+$/.test(profileData?.current_salary)
            ? profileData?.current_salary
            : null,
          current_salary_currency_id: profileData.current_salary_currency
            ? profileData?.current_salary_currency?.currency_code
            : {
                label: "USD",
                code: 229,
              },
          job_type: profileData.job_type
            ? { code: profileData.job_type, label: profileData.job_type }
            : null,
          job_time: profileData.job_time
            ? { code: profileData.job_time, label: profileData.job_time }
            : null,
        };
        return true;
      }
      return false;
    },
    options() {
      return {
        available: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
        extraHours: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
        flexible: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
        jobType: [
          { code: "Full Time", label: "Full Time" },
          { code: "Part Time", label: "Part Time" },
          { code: "Flexible", label: "Flexible" },
        ],
        jobTime: [
          { code: "Permanent", label: "Permanent" },
          { code: "Temporary", label: "Temporary" },
          { code: "Visiting/locum", label: "Visiting/locum" },
          { code: "Flexible", label: "Flexible" },
        ],
        preferred_country: this.preferred_country || [],
        locationRegion: this.preferred_regionOption,
        currency: this.getCurrency || [],
      };
    },
    preferred_regionOption() {
      return this.locationRegion
        ? this.locationRegion.filter((val) => {
            return (
              val.job_only != true &&
              !this.profile["preferred_regions"].some(
                ({ code }) => code === val.code
              )
            );
          })
        : [] || [];
    },
    preferred_country() {
      let regions =
        this.profile &&
        this.profile.preferred_regions &&
        this.profile.preferred_regions.map((e) => {
          return e.code;
        });
      return this.profile.preferred_regions
        ? this.PreferredCountryFilterByRegion({ regions })
            .sort((a, b) => (a.label === "United Arab Emirates" ? -1 : 1))
            .filter(
              (val) =>
                !this.profile["preferred_country_id"].some(
                  ({ code }) => code === val.code
                )
            )
        : null;
    },
  },
  methods: {
    ...mapActions(["initFetchAvailabilityInfoOptions", "showToast"]),
    async onSubmit() {
      const isValid = await this.$refs.editAvailabilityInfo.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      let preferred_regions_payload = this.payload.preferred_regions;
      let preferred_country_payload = this.payload.preferred_country_id;
      let availability_payload = {
        ...this.payload,
        candidate_uid: this.profile.candidate_uid,
        available: this.profile.available,
        available_from: this.profile.available_from,
        notice_period_days: this.profile.notice_period_days
          ? this.profile.notice_period_days
          : null,
        expected_join_date: this.profile.available
          ? this.profile.available_from
          : null,
        salary_expectation_min: this.profile.salary_expectation_max
          ? this.profile.salary_expectation_min || null
          : null,
        salary_expectation_max: this.profile.salary_expectation_min
          ? this.profile.salary_expectation_max || null
          : null,
        salary_expectation_currency_id:
          this.profile.salary_expectation_min &&
          this.profile.salary_expectation_max
            ? this.profile?.salary_expectation_currency_id?.code
            : null,
        current_salary: this.profile.current_salary,
        current_salary_currency_id: this.profile.current_salary
          ? this.profile?.current_salary_currency_id?.code
          : null,
        job_type: this.profile.job_type?.code,
        job_time: this.profile.job_time?.code,
      };
      delete availability_payload.preferred_regions;
      delete availability_payload.preferred_country_id;
      this.modalCallBack(true, {
        candidate_data: availability_payload,
        region_data: preferred_regions_payload,
        country_data: preferred_country_payload,
      });
    },
    modalCallBack(action, payload) {
      this.$emit("modalCallBack", action, payload);
    },
    onChange: debounce(function (value) {
      Vue.set(this.profile, "notice_period_days", value);
      Vue.set(this.payload, "notice_period_days", value);
    }, 500),
  },
  mounted() {
    this.initFetchAvailabilityInfoOptions();
  },
};
</script>
