<template>
  <div>
    <CRow>
      <CCol md="6">
        <CRow class="row mb-3">
          <label name="lbl_document_type" class="col-lg-12 col-md-12"
            >Document Type</label
          >
          <div class="col-lg-12 col-md-12 col-sm-12">
            <Select
              name="document_type"
              :value="uploadData.document_type || `CV / Resume`"
              @input="handleChangeSelect"
              :options="
                options && options['document_type']
                  ? options['document_type']
                  : []
              "
              :taggable="false"
              :multiple="false"
              :clearable="false"
            />
          </div>
        </CRow>
      </CCol>
      <CCol md="6">
        <CRow class="row mb-3">
          <label name="lbl_upload-document" class="col-lg-12 col-md-12"
            >Upload Document</label
          >
          <div class="col-lg-12 col-md-12 col-sm-12">
            <input
              name="upload-document"
              type="file"
              ref="fileref"
              :accept="arraytoComma(fileType)"
              @change="selectedFile"
            />
          </div>
          <!-- <div v-if="isImageSelected">
            <UploadDocument
              :documentType="uploadData.document_type"
              :isProfilePic="isProfilePic"
              :fileData="fileData"
              @closeModal="closeModal"
              v-on="$listeners"
            />
          </div> -->
        </CRow>
      </CCol>
    </CRow>
    <CToaster v-if="isFileError" :autohide="3000" position="top-right">
      <CToast :show="true" class="bg-danger text-white"
        >File too Big, please select a file less than 2mb</CToast
      >
    </CToaster>
    <CToaster v-if="isFileTpeValid" :autohide="5000" position="top-right">
      <CToast :show="true" class="bg-danger text-white"
        >Allowed File Type {{ FileTypeError }}</CToast
      >
    </CToaster>
    <CropperModal
      v-if="cropperModal.isShowPopup"
      :isShowPopup="cropperModal.isShowPopup"
      :fileData="cropperModal.fileData"
      modalColor="primary"
      :buttonLabel="cropperModal.buttonLabel"
      @modalCallBack="cropperModalCallBack"
      :aspectRatio="cropperModal.aspectRatio"
    />
  </div>
</template>
<script>
import UploadDocument from "@/containers/CandidateProfile/UploadDocument";
import Select from "@/components/reusable/Fields/Select.vue";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import CropperModal from "@/components/reusable/CropperModal";
export default {
  components: { UploadDocument, Select, CropperModal },
  data() {
    return {
      uploadData: {},
      fileData: {},
      isFileError: false,
      isFileTpeValid: false,
      FileTypeError: null,
      isImageSelected: false,
      cropperModal: {
        isShowPopup: false,
        fileData: null,
        buttonLabel: "Apply",
        aspectRatio: 1,
      },
    };
  },
  computed: {
    ...mapGetters([
      "documentType",
      "uploaded",
      "isCandidateSupplierFromAccessToken",
    ]),
    options() {
      return {
        document_type: this.documentTypeCustomOrder.map((data) => data) || [],
      };
    },
    documentTypeCustomOrder() {
      const sortOrder = [1];
      let excludeType = [23, 24, 26];
      if (this.isCandidateSupplierFromAccessToken)
        excludeType = [...excludeType, 20];
      return (
        (this.documentType &&
          this.documentType.length &&
          this.documentType
            .filter(({ code }) => !excludeType.includes(code))
            .sort(
              (a, b) => sortOrder.indexOf(b.code) - sortOrder.indexOf(a.code)
            )) ||
        []
      );
    },
    fileType() {
      if (this.documentType.length) {
        if (
          this.uploadData.document_type &&
          this.uploadData.document_type.code
        ) {
          let fileext = this.documentType
            .filter(({ code }) => code === this.uploadData.document_type.code)
            .map((d) => d.allowed_extensions)[0];
          fileext = fileext.map((d) => d.toLowerCase());
          return fileext;
        } else {
          let fileext = this.documentType
            .filter(({ code }) => code === 1)
            .map((d) => d.allowed_extensions)[0];
          fileext = fileext.map((d) => d.toLowerCase());
          return fileext;
        }
      }
      return [];
    },
    isProfilePic() {
      if (
        this.uploadData.document_type &&
        this.uploadData.document_type.label &&
        this.uploadData.document_type.label === "Photograph"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions(["fetchDocumentType", "fetchUploadedDocument"]),
    closeModal() {
      this.$refs.fileref.value = "";
      this.uploadData = {};
      this.fileData = {};
      this.isImageSelected = false;
    },
    getExtension(filename) {
      let parts = filename.split(".");
      return parts[parts.length - 1];
    },
    arraytoComma(d) {
      return d?.map((v) => "." + v).join(", ");
    },
    selectedFile(event) {
      this.isFileError = false;
      this.isFileTpeValid = false;
      const size = event.target.files[0]?.size;
      if (Math.round(size / (1024 * 1024)) <= 2) {
        const file = event.target.files[0];
        const fileExt = this.getExtension(file.name);
        const fileTypeString = this.fileType;
        if (fileTypeString.includes(fileExt)) {
          if (file.type.startsWith("image/")) {
            this.cropperModal.fileData = file;
            this.cropperModal.isShowPopup = true;
          } else {
            this.handleFileUploads(file);
          }
        } else {
          this.isFileTpeValid = true;
          this.FileTypeError = this.arraytoComma(fileTypeString);
          this.$refs.fileref.value = "";
          this.showToaster();
        }
      } else {
        this.$refs.fileref.value = "";
        this.isFileError = true;
        this.showToaster();
      }
    },
    handleFileUploads(event) {
      this.payload = {
        ...this.payload,
        name: ["file"],
        value: event,
        isPhotoUpload: false,
      };
      this.$emit("upload", this.payload);
    },
    handleChangeSelect(name, value) {
      Vue.set(this.uploadData, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code || value : null,
      };
    },
    cropperModalCallBack(action, data) {
      if (action === false) {
        this.cropperModal.isShowPopup = false;
        this.cropperModal.fileData = null;
        this.cropperModal.aspectRatio = 0;
        this.$refs.fileref.value = "";
      } else {
        let { fileBlob, fileName } = data;
        const payload = {
          name: ["file"],
          document_type: this.payload?.document_type,
          document_name: fileName,
          isPhotoUpload: true,
          comments: null,
          blobData: fileBlob,
        };

        this.$emit("upload", payload);
        this.cropperModal.isShowPopup = false;
        this.cropperModal.fileData = null;
      }
    },
    showToaster() {
      setTimeout(() => {
        this.isFileError = false;
        this.isFileTpeValid = false;
      }, 5000);
    },
  },
  watch: {
    uploaded() {
      if (this.uploaded) {
        if (this.$refs.fileref) this.$refs.fileref.value = "";
        this.uploadData = {};
        // this.fetchUploadedDocument();
        this.fileData = {};
        this.isImageSelected = false;
        this.payload = {};
      }
    },
  },
  mounted() {
    this.fetchDocumentType();
  },
};
</script>