<template>
  <div>
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">Personal Information Edit</h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Close</CButton
        >
        <CButton
          type="button"
          color="primary"
          class="px-4"
          @click="onSubmit()"
          v-if="activeTab === 1"
          >Save</CButton
        >
      </template>
      <LoadingOverlay v-if="!isFetching" />
      <div v-else>
        <CTabs variant="tabs" :active-tab.sync="activeTab">
          <CTab title="CV / Resume Preview">
            <div class="p-2">
              <CVPreview />
            </div>
          </CTab>
          <CTab title="Personal Information Edit">
            <div class="p-2">
              <ValidationObserver ref="editBasicInfo" v-slot="{ handleSubmit }">
                <form
                  @submit.prevent="handleSubmit(onSubmit)"
                  name="editBasicInfo"
                >
                  <CRow>
                    <CCol md="6">
                      <CRow
                        class="row mb-3"
                        :class="profile.title_id ? '' : 'requiredfield'"
                      >
                        <label class="required col-lg-12 col-md-12"
                          >Title</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <Select
                              name="title_id"
                              @input="handleChangeSelect"
                              :value="profile.title_id"
                              :options="
                                options && options['titles']
                                  ? options['titles']
                                  : []
                              "
                              :taggable="false"
                              :multiple="false"
                              :clearable="true"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="6">
                      <CRow
                        class="mb-3"
                        :class="profile.first_name ? '' : 'requiredfield'"
                      >
                        <label
                          name="lbl_first_name"
                          class="required col-lg-12 col-md-12"
                          >First Name</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            rules="required|alpha_spaces"
                            v-slot="{ errors }"
                          >
                            <TextInput
                              name="first_name"
                              :value="profile.first_name"
                              @input="handleInput"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label class="col-lg-12 col-md-12"
                          >Middle Name(s)</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            rules="alpha_spaces"
                            v-slot="{ errors }"
                          >
                            <TextInput
                              name="middle_name"
                              :value="profile.middle_name"
                              @input="handleInput"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="6">
                      <CRow
                        class="mb-3"
                        :class="profile.surname ? '' : 'requiredfield'"
                      >
                        <label
                          name="lbl_surname"
                          class="required col-lg-12 col-md-12"
                          >Last Name</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            rules="required|alpha_spaces"
                            v-slot="{ errors }"
                          >
                            <TextInput
                              name="surname"
                              :value="profile.surname"
                              @change="handleInput"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6">
                      <CRow class="mb-3">
                        <label name="lbl_known_as" class="col-lg-12 col-md-12"
                          >Known as</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            rules="alpha_spaces"
                            v-slot="{ errors }"
                          >
                            <TextInput
                              name="known_as"
                              :value="profile.known_as"
                              @change="handleInput"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="6">
                      <CRow
                        class="mb-3"
                        :class="profile.dob ? '' : 'requiredfield'"
                      >
                        <label
                          name="lbl_dob"
                          class="required col-lg-12 col-md-12"
                          >Date of Birth</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12 pr-0">
                          <ValidationProvider
                            name="dob"
                            rules="required|date_validate"
                            v-slot="{ errors }"
                          >
                            <DateInput
                              name="dob"
                              :value="profile.dob"
                              @change="handleDatePickerChange"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6">
                      <CRow
                        class="mb-3"
                        :class="profile.gender_id ? '' : 'requiredfield'"
                      >
                        <label
                          name="lbl_gender_id"
                          class="required col-lg-12 col-md-12"
                          >Gender</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <Select
                              name="gender_id"
                              :value="profile.gender_id"
                              :options="
                                options && options['gender']
                                  ? options['gender']
                                  : []
                              "
                              @input="handleChangeSelect"
                              :taggable="false"
                              :multiple="false"
                              :clearable="true"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="6">
                      <CRow
                        class="mb-3"
                        :class="profile.nationality_ids ? '' : 'requiredfield'"
                      >
                        <label
                          name="lbl_nationality_id"
                          class="required col-lg-12 col-md-12"
                          >Nationality</label
                        >
                        <div class="col infoicon-multi-select">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <Select
                              name="nationality_ids"
                              @input="handleChangeMultiSelectAsArray"
                              :value="profile.nationality_ids"
                              :options="
                                options && options['nationality']
                                  ? options['nationality']
                                  : []
                              "
                              :taggable="false"
                              :multiple="true"
                              option_label="nationality"
                              :clearable="true"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                        <div class="position-relative multi-select-icon-div">
                          <CIcon
                            name="cil-info"
                            class="hinticon-multi-select"
                            v-c-tooltip="'Select one or more'"
                          />
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6">
                      <CRow class="mb-3">
                        <label
                          name="lbl_passport_no"
                          class="col-lg-12 col-md-12"
                          >Passport Number</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <TextInput
                            name="passport_number"
                            :value="profile.passport_number"
                            @change="handleInput"
                          />
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6">
                      <CRow
                        class="mb-3"
                      >
                        <label
                          name="lbl_birthplace"
                          class="col-lg-12 col-md-12"
                          >Birthplace</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <Select
                            name="birth_place"
                            :value="profile.birth_place"
                            :options="
                              options && options['birth_place']
                                ? options['birth_place']
                                : []
                            "
                            :taggable="false"
                            :multiple="false"
                            :clearable="true"
                            @input="handleChangeSelect"
                          />
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="6">
                      <CRow
                        class="mb-3"
                      >
                        <label
                          name="lbl_marital_status"
                          class="col-lg-12 col-md-12"
                          >Marital Status</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <RadioButton
                            name="marital_status"
                            :value="profile.marital_status"
                            :options="
                              options && options['marital_status']
                                ? options['marital_status']
                                : []
                            "
                            label="marital_status"
                            @change="handleChangeRadio"
                          />
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <div v-if="profile.marital_status == 'Married'">
                    <CRow>
                      <CCol md="6">
                        <CRow class="mb-3">
                          <label name="lbl_married" class="col-lg-12 col-md-12"
                            >Marital Partner Also Seeking Clinical Job?</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <RadioButton
                              name="marital_partner_seeking_job"
                              :value="profile.marital_partner_seeking_job"
                              :options="
                                options && options['boolean']
                                  ? options['boolean']
                                  : []
                              "
                              label="marital_partner_seeking_job"
                              @change="handleChangeRadio"
                            />
                          </div>
                        </CRow>
                      </CCol>
                      <CCol
                        md="6"
                        v-if="profile.marital_partner_seeking_job == true"
                      >
                        <CRow class="mb-3">
                          <label
                            name="lbl_spouse_registered"
                            class="col-lg-12 col-md-12"
                            >Spouse Registered?</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <RadioButton
                              name="spouse_registered"
                              :value="profile.spouse_registered"
                              :options="
                                options && options['boolean']
                                  ? options['boolean']
                                  : []
                              "
                              label="spouse_registered"
                              @change="handleChangeRadio"
                            />
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                    <CRow v-if="profile.marital_partner_seeking_job == true">
                      <CCol md="6">
                        <CRow class="mb-3">
                          <label
                            name="lbl_name_of_spouse"
                            class="col-lg-12 col-md-12"
                            >Name of Spouse</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="alpha_spaces"
                              v-slot="{ errors }"
                            >
                              <TextInput
                                name="name_of_spouse"
                                :value="profile.name_of_spouse"
                                label="name_of_spouse"
                                @input="handleInput"
                                :error="errors[0]"
                              ></TextInput>
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="6">
                        <CRow class="mb-3">
                          <label
                            name="lbl_spouse_email"
                            class="col-lg-12 col-md-12"
                            >Spouse Email</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="email"
                              v-slot="{ errors }"
                            >
                              <TextInput
                                name="spouse_email"
                                :value="profile.spouse_email"
                                label="spouse_email"
                                @input="handleInput"
                                :error="errors[0]"
                              ></TextInput>
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                  </div>
                  <CRow>
                    <CCol md="6">
                      <CRow class="mb-3">
                        <label
                          name="lbl_dependents"
                          class="col-lg-12 col-md-12"
                          v-if="profile.dependents > 0"
                        >
                          Dependents (under 18)
                          <b>Selected - {{ profile.dependents }}</b>
                        </label>
                        <label class="col-lg-12 col-md-12" v-else>
                          Dependents (under 18)
                          <b>Selected - 0</b>
                        </label>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <v-slider
                            name="dependents"
                            :min="minDependants"
                            :max="maxDependants"
                            :value="profile.dependents"
                            @change="onChange"
                          />
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="6">
                      <CRow class="mb-3">
                        <label
                          name="lbl_relocate_family"
                          class="col-lg-12 col-md-12"
                          >Will You Relocate Your Family?</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <RadioButton
                            name="relocate_family"
                            :value="profile.relocate_family"
                            :options="
                              options && options['boolean']
                                ? options['boolean']
                                : []
                            "
                            label="relocate_family"
                            @change="handleChangeRadio"
                          />
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                </form>
              </ValidationObserver>
            </div>
          </CTab>
        </CTabs>
      </div>
    </CModal>
  </div>
</template>
<script>
const debounce = require("lodash.debounce");
import Vue from "vue";
import m from "moment";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
import EditSection from "./EditSection";
import { min } from "vee-validate/dist/rules";
import { mapActions, mapGetters } from "vuex";
import UploadDocument from "@/containers/CandidateProfile/UploadDocument";
import { BASE_URL } from "@/service_urls";
import { extend } from "vee-validate";
import {
  required,
  numeric,
  alpha_spaces,
  alpha_num,
  max_value,
  min_value,
} from "vee-validate/dist/rules";
extend("numeric", { ...numeric, message: "Only numbers are allowed" });
extend("required", { ...required, message: "This field is required" });
extend("alpha_spaces", {
  ...alpha_spaces,
  message: "Only Alphabets & Spaces are allowed",
});
extend("alpha_num", {
  ...alpha_num,
  message: "Only Alphabets & numbers are allowed",
});
extend("min_value", { ...min_value, message: "Enter a valid Candidate id" });
extend("max_value", { ...max_value, message: "Enter a valid Candidate id" });
extend("date_validate", (value) => {
  if (m(value).isValid()) {
    return true;
  }
  return "Invalid date! Enter a valid date";
});
extend("ExpiryDate1", (value) => {
  let inputdate = m(value);
  let currentDate = m();
  let duration = m.duration(inputdate.diff(currentDate));
  let months = duration.months();
  let isPrevious = inputdate.isBefore(currentDate);
  if (isPrevious) {
    return "Your Passport had Expired!! Please Renew";
  } else if (months <= 6) {
    return "Your Passport Is Going To Expire Soon - Please Renew";
  } else {
    return true;
  }
});
export default {
  mixins: [EditSection],
  props: {
    candidateInfo: {
      type: Object,
      default: null,
    },
    isShowPopup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      profile: {},
      minDependants: 0,
      maxDependants: 10,
    };
  },
  computed: {
    ...mapGetters([
      "titles",
      "gender",
      "locationCountryInfo",
      "nationalityCountryInfo",
      "isFetchingFilter",
      "candidateProfile",
    ]),
    isFetching() {
      if (
        isObject(this.candidateInfo) &&
        !isEmptyObjectCheck(this.candidateInfo) &&
        !this.isFetchingFilter
      ) {
        let profileData = this.candidateInfo;
        this.profile = {
          ...profileData,
          customer_uid: profileData?.customer_uid,
          candidate_uid: profileData?.candidate_uid,
          title_id:
            profileData.title_id !== null
              ? {
                  code: profileData?.title?.title_id,
                  label: profileData?.title?.title,
                }
              : null,
          gender_id:
            profileData.gender_id != null
              ? {
                  code: profileData?.gender?.gender_id,
                  label: profileData?.gender?.gender,
                }
              : null,
          nationality_ids:
            profileData.nationality_ids !== null
              ? this.nationalityCountryInfo.filter(({ code }) =>
                  profileData.nationality_ids.includes(code)
                )
              : null,
          birth_place:
            profileData.birth_place_id !== null
              ? {
                  code: profileData?.birth_place?.country_id,
                  label: profileData?.birth_place?.country_name,
                }
              : null,
          marital_status: profileData?.marital_status || null,
          dependents: profileData?.dependents,
          marital_partner_seeking_job: profileData?.marital_partner_seeking_job,
          spouse_registered: profileData?.spouse_registered,
          name_of_spouse: profileData?.name_of_spouse,
          spouse_email: profileData?.spouse_email,
          relocate_family: profileData?.relocate_family,
          passport_number: profileData?.passport_number,
        };
        return true;
      }
      return false;
    },
    nationalityOptions() {
      return (
        (this.nationalityCountryInfo &&
          this.profile &&
          this.profile["nationality_ids"] &&
          this.nationalityCountryInfo.filter((val) => {
            return !this.profile["nationality_ids"].some(
              ({ code }) => code === val.code
            );
          })) ||
        this.nationalityCountryInfo
      );
    },
    options() {
      return {
        gender: this.gender || [],
        birth_place: this.locationCountryInfo || [],
        nationality: this.nationalityOptions || [],
        titles: this.titles || [],
        marital_status: [
          { id: "Married", label: "Married" },
          { id: "Single", label: "Single" },
        ],
        boolean: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
          { id: null, label: "N/A" },
        ],
      };
    },
  },
  methods: {
    ...mapActions([
      "initFetchBasicInfoOptions",
      "showToast",
      "getCandidateIdByMail",
    ]),
    async onSubmit() {
      const isValid = await this.$refs.editBasicInfo.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      if (this.profile?.marital_status == "Single") {
        this.profile.marital_partner_seeking_job = null;
        this.profile.spouse_registered = null;
        this.profile.name_of_spouse = null;
        this.profile.spouse_email = null;
      }
      let appendAction = [];
      if (this.profile?.spouse_registered && this.profile?.spouse_email) {
        appendAction = this.getCandidateIdByMail(
          this.profile.spouse_email
        ).then((res) => {
          if (res.status == 200) {
            this.profile.spouse_profile_id = res.data;
            if (res.data == this.candidateProfile.candidate_uid) {
              this.showToast({
                class: "bg-danger text-white",
                message: "Spouse Mail ID should be different from your mail ID",
              });
              isValid = false;
            }
          } else {
            this.showToast({
              class: "bg-danger text-white",
              message: "No User found for spouse E-Mail ID",
            });
            isValid = false;
          }
        });
      }
      Promise.all([appendAction]).then((res) => {
        if (!isValid) return;
        this.payload = {
          ...this.payload,
          candidate_uid: this.profile?.candidate_uid,
          first_name: this.profile?.first_name,
          middle_name: this.profile?.middle_name,
          surname: this.profile?.surname,
          known_as: this.profile?.known_as,
          dob: this.profile?.dob,
          gender_id: this.profile?.gender_id?.code,
          nationality_ids:
            this.profile?.nationality_ids.map(({ code }) => code) || [],
          birth_place_id: this.profile?.birth_place?.code,
          marital_status: this.profile?.marital_status,
          dependents: this.profile?.dependents,
          marital_partner_seeking_job:
            this.profile.marital_partner_seeking_job !== ""
              ? this.profile.marital_partner_seeking_job
              : null,
          spouse_registered:
            this.profile.spouse_registered !== ""
              ? this.profile.spouse_registered
              : null,
          name_of_spouse:
            this.profile.name_of_spouse !== ""
              ? this.profile.name_of_spouse
              : null,
          spouse_email:
            this.profile.spouse_email !== "" ? this.profile.spouse_email : null,
          spouse_profile_id: this.profile.spouse_registered
            ? this.profile?.spouse_profile_id || null
            : null,
          relocate_family:
            this.profile.relocate_family !== ""
              ? this.profile.relocate_family
              : null,
        };
        this.modalCallBack(true, this.payload);
      });
    },
    modalCallBack(action, payload) {
      this.$emit("modalCallBack", action, payload);
    },
    onChange: debounce(function (value) {
      Vue.set(this.profile, "dependents", value);
      Vue.set(this.payload, "dependents", value);
    }, 500),
  },
  mounted() {
    this.initFetchBasicInfoOptions();
  },
};
</script>