var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CModal',{attrs:{"color":"primary","show":_vm.isShowPopup,"close-on-backdrop":false,"size":"lg"},on:{"update:show":function($event){_vm.isShowPopup=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h6',{staticClass:"modal-title"},[_vm._v("Availability Information Edit")]),_c('CButtonClose',{staticClass:"text-black",on:{"click":function($event){return _vm.modalCallBack(false)}}})]},proxy:true},{key:"footer",fn:function(){return [_c('CButton',{staticClass:"px-4",attrs:{"type":"button","variant":"outline","color":"primary"},on:{"click":function($event){return _vm.modalCallBack(false)}}},[_vm._v("Close")]),(_vm.activeTab === 1)?_c('CButton',{staticClass:"px-4",attrs:{"type":"button","color":"primary"},on:{"click":function($event){return _vm.onSubmit()}}},[_vm._v("Save")]):_vm._e()]},proxy:true}])},[(!_vm.isFetching)?_c('LoadingOverlay'):_c('div',[_c('CTabs',{attrs:{"variant":"tabs","active-tab":_vm.activeTab},on:{"update:activeTab":function($event){_vm.activeTab=$event},"update:active-tab":function($event){_vm.activeTab=$event}}},[_c('CTab',{attrs:{"title":"CV / Resume Preview"}},[_c('div',{staticClass:"p-2"},[_c('CVPreview')],1)]),_c('CTab',{attrs:{"title":"Availability Information Edit"}},[_c('div',{staticClass:"p-2"},[_c('ValidationObserver',{ref:"editAvailabilityInfo",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"name":"editAvailabilityInfo"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('CRow',{staticClass:"row mb-3"},[_c('label',{staticClass:"col-lg-12 col-md-12",attrs:{"name":"lbl_available"}},[_vm._v("Actively Seeking Employment")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('RadioButton',{attrs:{"name":"available","value":_vm.profile.available,"options":_vm.options && _vm.options['available']
                              ? _vm.options['available']
                              : []},on:{"change":_vm.handleChangeRadio}})],1)])],1),_c('CCol',{attrs:{"md":"6"}},[_c('CRow',{staticClass:"row mb-3"},[(_vm.profile.notice_period_days > 0)?_c('label',{staticClass:"col-lg-12 col-md-12",attrs:{"name":"lbl_notice_period_days"}},[_vm._v("Notice Period ("+_vm._s(_vm.profile.notice_period_days)+" days)")]):_c('label',{staticClass:"col-lg-12 col-md-12"},[_vm._v("Notice Period (in days)")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('v-slider',{attrs:{"name":"notice_period_days","min":_vm.minDependants,"max":_vm.maxDependants,"value":_vm.profile.notice_period_days},on:{"change":_vm.onChange}})],1)])],1)],1),_c('CRow',{attrs:{"md":"6"}},[_c('CCol',{attrs:{"md":"6"}},[_c('div',[_c('CRow',{staticClass:"row mb-3"},[_c('label',{staticClass:"col-lg-12 col-md-12",attrs:{"name":"lbl_available"}},[_vm._v("Available From")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"date_validate|dateBetween"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('DateInput',{attrs:{"futureYear":true,"onlyMonthAndYear":true,"name":"available_from","value":_vm.profile.available_from,"error":errors[0]},on:{"change":_vm.handleDatePickerChange}})]}}],null,true)})],1)])],1)])],1),_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('CRow',{staticClass:"row mb-3"},[_c('label',{staticClass:"col-lg-12 col-md-12",attrs:{"name":"lbl_preferred_regions"}},[_vm._v("Preferred Regions")]),_c('div',{staticClass:"col infoicon-multi-select"},[_c('Select',{attrs:{"name":"preferred_regions","value":_vm.profile['preferred_regions'],"options":_vm.options && _vm.options['locationRegion']
                              ? _vm.options['locationRegion']
                              : [],"taggable":false,"multiple":true,"clearable":true},on:{"change":_vm.handleChangeMultiSelectAsArray}})],1),_c('div',{staticClass:"position-relative multi-select-icon-div"},[_c('CIcon',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:('Select one or more'),expression:"'Select one or more'"}],staticClass:"hinticon-multi-select",attrs:{"name":"cil-info"}})],1)])],1),_c('CCol',{attrs:{"md":"6"}},[_c('CRow',{staticClass:"row mb-3"},[_c('label',{staticClass:"col-lg-12 col-md-12",attrs:{"name":"lbl_preferred_regions"}},[_vm._v("Preferred Country")]),_c('div',{staticClass:"col infoicon-multi-select"},[_c('Select',{attrs:{"name":"preferred_country_id","value":_vm.profile.preferred_country_id,"options":_vm.options && _vm.options['preferred_country']
                              ? _vm.options['preferred_country']
                              : [],"taggable":false,"multiple":true,"clearable":true},on:{"change":_vm.handleChangeMultiSelectAsArray}})],1),_c('div',{staticClass:"position-relative multi-select-icon-div"},[_c('CIcon',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:('Select one or more'),expression:"'Select one or more'"}],staticClass:"hinticon-multi-select",attrs:{"name":"cil-info"}})],1)])],1)],1),_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('CRow',{staticClass:"row mb-3"},[_c('label',{staticClass:"col-lg-12 col-md-12",attrs:{"name":"lbl_current_salary"}},[_vm._v("Current Salary")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('CRow',[_c('CCol',{staticClass:"pr-0",staticStyle:{"max-width":"50%"}},[_c('ValidationProvider',{attrs:{"rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('TextInput',{attrs:{"name":"current_salary","value":_vm.profile.current_salary,"min":0,"type":"Number","CSS_Style":"max-height: 31px","error":errors[0],"onkeydown":_vm.restrictedKeys},on:{"change":_vm.handleInput}})]}}],null,true)})],1),_c('CCol',{staticClass:"p-0 ml-1",staticStyle:{"max-width":"25%"}},[_c('Select',{attrs:{"name":"current_salary_currency_id","value":_vm.profile.current_salary_currency_id,"options":_vm.options && _vm.options['currency']
                                  ? _vm.options['currency']
                                  : [],"taggable":false,"clearable":false,"disabled":_vm.isDisabled},on:{"change":_vm.handleChangeSelect}})],1)],1)],1)])],1),_c('CCol',{attrs:{"md":"6"}},[_c('CRow',{staticClass:"row mb-3"},[_c('label',{staticClass:"col-lg-12 col-md-12",attrs:{"name":"lbl_salary_expectation"}},[_vm._v("Salary Expectation(Min - Max)")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('CRow',[_c('CCol',{staticClass:"pr-0",staticStyle:{"max-width":"30%"}},[_c('ValidationProvider',{attrs:{"name":"salary_expectation_min","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('TextInput',{attrs:{"name":"salary_expectation_min","value":_vm.profile.salary_expectation_min,"placeholder":"Min","min":0,"type":"Number","CSS_Style":"max-height: 31px","onkeydown":_vm.restrictedKeys,"error":errors[0]},on:{"change":_vm.handleInput}})]}}],null,true)})],1),_vm._v(" _ "),_c('CCol',{staticClass:"p-0",staticStyle:{"max-width":"30%"}},[_c('ValidationProvider',{attrs:{"name":"salary_expectation_max","rules":"numeric|minMaxCheck:@salary_expectation_min"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('TextInput',{attrs:{"name":"salary_expectation_max","value":_vm.profile.salary_expectation_max,"placeholder":"Max","min":0,"type":"Number","CSS_Style":"max-height: 31px","onkeydown":_vm.restrictedKeys,"error":errors[0]},on:{"change":_vm.handleInput}})]}}],null,true)})],1),_c('CCol',{staticClass:"ml-1 p-0",staticStyle:{"max-width":"25%"}},[_c('Select',{attrs:{"name":"salary_expectation_currency_id","value":_vm.profile.salary_expectation_currency_id,"options":_vm.options && _vm.options['currency']
                                  ? _vm.options['currency']
                                  : [],"taggable":false,"clearable":false,"disabled":_vm.isDisabled},on:{"change":_vm.handleChangeSelect}})],1)],1)],1)])],1)],1),_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('CRow',{staticClass:"row mb-3"},[_c('label',{staticClass:"col-lg-12 col-md-12"},[_vm._v("Contract Type")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('div',{staticClass:"d-flex field-align"},[_c('div',{staticClass:"flex-fill mr-1"},[_c('Select',{attrs:{"name":"job_type","value":_vm.profile.job_type,"options":_vm.options && _vm.options['jobType']
                                  ? _vm.options['jobType']
                                  : [],"taggable":false,"multiple":false,"clearable":true},on:{"change":_vm.handleChangeSelect}})],1),_c('div',{staticClass:"flex-fill mr-1"},[_c('Select',{attrs:{"name":"job_time","value":_vm.profile.job_time,"options":_vm.options && _vm.options['jobTime']
                                  ? _vm.options['jobTime']
                                  : [],"taggable":false,"multiple":false,"clearable":true},on:{"change":_vm.handleChangeSelect}})],1)])])])],1)],1)],1)]}}])})],1)])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }